

import React, { useEffect, useState } from "react";
// import Loader from "react-loader-spinner";
import {TailSpin} from "react-loader-spinner";
import TagManager from 'react-gtm-module'

export const UploadPictureToFirebase=(props)=>{
    const [change,SetChange]=useState(true);
    const [isLoading,setIsloading]=useState(false);

    const [file, setFile] = useState(undefined);
    const [base64, setBase64] = useState('');

    
    useEffect(()=>{
      if(base64!==''){
        if(change===true){
            props?.setUrl(base64);
              SetChange(false);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[base64])
    const handleImageAsFile = (e) => {
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files[0];
      if (reader !== undefined && file !== undefined) {
        reader.onloadend = () => {
          setFile(file)
        }
        reader.readAsDataURL(file);
      }
     }
    const onChange = () => {
      if (file) {
        const reader = new FileReader();
        reader.onload = _handleReaderLoaded
        reader.readAsBinaryString(file)
      }
    }

    const _handleReaderLoaded = (readerEvt) => {
      let binaryString = readerEvt.target.result;
      setBase64(btoa(binaryString))
    }
     const handleFireBaseUpload = e => {
      TagManager.dataLayer({dataLayer: {event: 'upload_receipt'}})
      setIsloading(true);
      e.preventDefault()
      onChange();
     }
      const padding=(props.upload===true)?{marginTop:'20px',height:' 4vh',padding: '0 25%'}:{};
      const inputcss=(props.upload===true)?{padding:'2vw 0vw',textAlignLast:'center'}:{};
  return (
      <div className="App">
      <form onSubmit={handleFireBaseUpload}>
          <input 
            style={inputcss}
            className="upload" 
            type="file"
            accept=".jpef, .png, .jpg" 
            onChange={handleImageAsFile}
            />
          {!isLoading
            ?(file!==undefined)?
                <button 
                  style={padding}
                  className="ac-btn ac-btn-darkmode
                  ">
                  העלאה >>
                  </button>
                  :null
            :<TailSpin
                style={{marginTop:'10px'}}
                type="ThreeDots"
                color="#004B9D"
                height={100}
                width={100}
            />
            }
        </form>
      </div>
    )
  }