import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/desktopLogo.png";
import desktop from "../assets/img/desktop.png";
import "../assets/css/Loader.css";
import "../assets/css/DesktopPage.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { isNumberKey } from "../Config/Utils";
import SendSmsToMobile from "../Components/Popup/SendSmsToMobile";
import TagManager from "react-gtm-module";

export const DesktopPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading) {
      const ele = document.getElementById("ac-progess-loading");
      if (ele) {
        ele.remove();
        setLoading(false);
      }
    }
  }, [loading]);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [isSend, setIssend] = useState(false);
  const [number, setNumber] = useState("");
  const [daata, setDaata] = useState("");
  const [btnBye, setBtnBye] = useState(false);
  // const onSubmit = (data) => {
  //   TagManager.dataLayer({ dataLayer: { event: "sms_desktop" } });
  //   setNumber(data.Phone);
  // };
  const onSubmit = async (data) => {
    // console.log("DATA:", data);
    TagManager.dataLayer({ dataLayer: { event: "sms_desktop_request" } });
    const dataSend = {
      phone: data.Phone,
    };

    // const rawResponse = await fetch("https://oreoprod.activated.co.il/sendSMS.php", {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(dataSend),
    // });

    try {
      let res = await fetch("https://oreoprod.activated.co.il/sendSMS.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(dataSend),
      });
      setBtnBye(true);
      let resJson = await res.json();
      if (res.status === 200) {
        console.log("Sent");
        setBtnBye(true);
      } else {
        console.log("Not Sent");
      }
    } catch (err) {
      console.log(err);
    }
    TagManager.dataLayer({ dataLayer: { event: "sms_sent_desktop" } });
  };
  const helpme = (e) => {
    if (!isNumberKey(e)) {
      e.preventDefault();
    }
  };
  const FormCurrent = () => {
    return (
      <div>
        <span className="ac-desktop-ttl">
          מוצאים ת'אוראו במולטיוורס
          <br />
          ויכולים לזכות במיני קופר!
        </span>
        <span className="ac-desktop-txt">
          באמצעות הנייד מחפשים בגוגל: "אוראו במולטיוורס"
          <br />
          או מוסיפים למטה את המספר שלך ונשלח לך{" "}
          <span className="ac-sms-word">SMS</span>
          <br />
          עם לינק להצטרפות לפעילות. בהצלחה!
        </span>
        {btnBye == false ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ac-tel-input">
              <label>נייד:</label>
              <input
                className="ac-form-help"
                type="tel"
                onKeyPress={helpme}
                maxLength="10"
                placeholder="0500000000..."
                {...register("Phone", {
                  required: "טלפון לא תקין ",
                  minLength: 10,
                  maxLength: 10,
                })}
              />
            </div>

            <button className="ac-btn ac-sms-send-btn" type="submit">
              שלחו לי <span className="ac-sms-word"> SMS </span>{" "}
            </button>
          </form>
        ) : (
          <button
            onClick={() => {
              TagManager.dataLayer({ dataLayer: { event: "sms_desktop_bye" } });
              window.location.replace("https://oreoprod.activated.co.il/");
            }}
            className="ac-bye"
          >
            תודה, ביי
          </button>
        )}
        <ErrorMessage
          errors={errors}
          name="Phone"
          render={({ message }) => (
            <p
              className="ac-erreur-form"
              style={{ marginLeft: "auto", padding: "5px" }}
            >
              טלפון לא תקין
            </p>
          )}
        />
        <p style={{ paddingTop: "30px" }}>
          *ה- <span className="ac-sms-word">SMS</span> היחיד שנשלח לך הוא לינק
          לפעילות שזמינה רק במובייל.
          <br />
          אנחנו משתמשים בעוגיות אוראו באתר הפעילות, אבל אפשר לטעום אותן רק
          במציאות.
        </p>
      </div>
    );
  };
  const EndD = () => {
    return (
      <div>
        <p> נתראה במתחם 😎 </p>
      </div>
    );
  };
  // const FormCurrent = () => {
  //   return (
  //     <div>
  //       {/* <p>
  //         כמו כל דבר טוב.... <br />
  //         הפעילות נגמרה <br />
  //         אבל מוזמנים להמשיך ולשחק <br />
  //         בשביל הכיף, ולמצוא ת'אוראו בחנויות 😊
  //         <br />
  //         <br />
  //         <br />
  //         חפשו במובייל oreoprod.activated.co.il ותיהנו *מתחם המשחקים פעיל רק
  //         במובייל
  //       </p> */}
  //     </div>
  //   );
  // };
  return (
    <div className="ac-desktop-page ac-loader-wrapper">
      <div className="ac-left-side">
        <img src={desktop} alt="Logo" />
      </div>
      <div className="ac-logo-oreo">
        <img src={logo} alt="Logo" />

        <FormCurrent />

        {/* {number === "" ? (
          <FormCurrent />
        ) : isSend ? (
          <EndD />
        ) : (
          <SendSmsToMobile
            phoneNumber={number}
            setphone={setNumber}
            isEnd={setIssend}
          />
        )} */}
      </div>
    </div>
  );
};

export default DesktopPage;
