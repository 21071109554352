import React from "react";
import { milis } from "../Config/Utils";



export const formatTime = (timer) => {
  const getSeconds = `0${timer % 60}`.slice(-2);
  const minutes = `${Math.floor(timer / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);

  return `${getMinutes}:${getSeconds}`;
};
export const formatTime_back = (timer) => {
  var timterTemp = timer === 0 ? 1 : timer;
  const getSeconds = `0${timterTemp % 60}`.slice(-2);
  const minutes = `${Math.floor(timterTemp / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  if (timer === 0) {
    return `${getMinutes}:${getSeconds}:00`;
  } else {
    return `${getMinutes}:${getSeconds}:${milis()}`;
  }
};

export const useTimer = (initialState = 0) => {
  const [timer, setTimer] = React.useState(initialState);
  const [isActive, setIsActive] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState(false);
  const countRef = React.useRef(null);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(countRef.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    setIsPaused(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(countRef.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  return {
    timer,
    isActive,
    isPaused,
    handleStart,
    handlePause,
    handleResume,
    handleReset,
  };
};
