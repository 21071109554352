import React from "react";
// import _B1 from '../../assets/Step/B1';

const Step_2 = (type) => {
  switch (type) {
    case "B1":
      return B1;
    case "B2":
      return B2;
    case "B3":
      return B3;
    case "B4":
      return B4;
    case "B5":
      return B5;
    case "B6":
      return B6;
    default:
      break;
  }
};
export default Step_2;
const B1 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //b1
        onClick={() => {
          found();
        }}
        position="-7 -4 -2"
        radius="0.3"
        color="blue"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo Not for B4
        onClick={() => {
          notFound();
        }}
        position="-11 5.5 1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-4 0.9 3.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b5
        onClick={() => {
          notFound();
        }}
        position="0 -1.8 8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b6
        onClick={() => {
          notFound();
        }}
        position="7 0.35 2.9"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.B1.image.data}`} />
    </a-scene>
  );
};
const B2 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //b2
        onClick={() => {
          found();
        }}
        position="7 1.2 -2.2"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo Not for B4
        onClick={() => {
          notFound();
        }}
        position="-11 5.5 1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-4 0.9 3.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b1
        onClick={() => {
          notFound();
        }}
        position="-7 -4 -2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b5
        onClick={() => {
          notFound();
        }}
        position="0 -1.8 8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b6
        onClick={() => {
          notFound();
        }}
        position="7 0.35 2.9"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.B2.image.data}`} />
    </a-scene>
  );
};
const B3 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //b3
        onClick={() => {
          found();
        }}
        position="5 4.5 -8.2"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo Not for B4
        onClick={() => {
          notFound();
        }}
        position="-11 5.5 1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-4 0.9 3.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b1
        onClick={() => {
          notFound();
        }}
        position="-7 -4 -2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b5
        onClick={() => {
          notFound();
        }}
        position="0 -1.8 8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b6
        onClick={() => {
          notFound();
        }}
        position="7 0.35 2.9"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.B3.image.data}`} />
    </a-scene>
  );
};
const B4 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //b4
        onClick={() => {
          found();
        }}
        position="-9 4.5 1"
        radius="0.3"
        color="blue"
        material="opacity: 0.0; transparent: true"
      />

      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-4 0.9 3.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b1
        onClick={() => {
          notFound();
        }}
        position="-7 -4 -2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b5
        onClick={() => {
          notFound();
        }}
        position="0 -1.8 8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b6
        onClick={() => {
          notFound();
        }}
        position="7 0.35 2.9"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.B4.image.data}`} />
    </a-scene>
  );
};
const B5 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //b5
        onClick={() => {
          found();
        }}
        position="0 -1.8 8"
        radius="0.3"
        color="blue"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b6
        onClick={() => {
          notFound();
        }}
        position="7 0.35 2.9"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo Not for B4
        onClick={() => {
          notFound();
        }}
        position="-11 5.5 1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-4 0.9 3.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b1
        onClick={() => {
          notFound();
        }}
        position="-7 -4 -2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.B5.image.data}`} />
    </a-scene>
  );
};
const B6 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //b6
        onClick={() => {
          found();
        }}
        position="7 0.35 2.9"
        radius="0.3"
        color="blue"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b5
        onClick={() => {
          notFound();
        }}
        position="0 -1.8 8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo Not for B4
        onClick={() => {
          notFound();
        }}
        position="-11 5.5 1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-4 0.9 3.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //b1
        onClick={() => {
          notFound();
        }}
        position="-7 -4 -2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.B6.image.data}`} />
    </a-scene>
  );
};
