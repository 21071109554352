import React, { useState } from "react";
import { Link } from "react-router-dom";
import PopupEnd from "../PopupEnd";
import TagManager from "react-gtm-module";
import CircleImg from "../../assets/img/popup-circle.png";

const FoundOreo = (props) => {
  const clickEvent = () => {
    props.desactivate(true);
    props.replay();
    // console.log(props.step);
    switch (props.step) {
      case 2:
        TagManager.dataLayer({ dataLayer: { event: "level_1" } });
        break;
      case 3:
        TagManager.dataLayer({ dataLayer: { event: "level_2" } });
        break;
      case 4:
        TagManager.dataLayer({ dataLayer: { event: "level_3" } });
        break;
      default:
        break;
    }
  };

  useState(() => {
    if (props.step === 4) {
      let el = document.getElementById("passtothanks");
      if (el) {
        // TagManager.dataLayer({ dataLayer: { event: "croatia" } });
        el.click();
      }
    } else {
      props.change();
    }
  }, [props.step]);

  if (props.step === 3) {
    return (
      // After Second Step
      <PopupEnd id="ac-found-oreo" events={clickEvent}>
        <img
          src={CircleImg}
          alt=""
          className="ac-img-responsive ac-popup-circle"
        />
        <span class="ac-popuptitle">
          בום! <br />
          מצאת ת׳אוראו
        </span>
        <p>נשארו רק עוד {5 - props.step} יקומים...</p>

        <Link to="" onClick={clickEvent} className="ac-btn ac-btn-goto-form">
          <div className="ac-inner-btn">
            <span>ליקום הבא</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link>
      </PopupEnd>
    );
  } else if (props.step === 4) {
    return (
      // After Third Step
      <PopupEnd id="ac-found-oreo" events={clickEvent}>
        <img
          src={CircleImg}
          alt=""
          className="ac-img-responsive ac-popup-circle"
        />
        <span class="ac-popuptitle">
          טיל! <br />
          מצאת ת׳אוראו
        </span>
        <p>נשאר לך עוד יקום אחד</p>

        <Link to="" onClick={clickEvent} className="ac-btn ac-btn-goto-form">
          <div className="ac-inner-btn">
            <span>ליקום הבא</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link>
      </PopupEnd>
    );
  } else if (props.step !== 4) {
    return (
      // After First Step
      <PopupEnd id="ac-found-oreo" events={clickEvent}>
        <img
          src={CircleImg}
          alt=""
          className="ac-img-responsive ac-popup-circle"
        />
        <span class="ac-popuptitle">
          סחטיין! <br />
          מצאת ת׳אוראו
        </span>
        <p> נשארו לך עוד {5 - props.step} יקומים</p>

        <Link to="" onClick={clickEvent} className="ac-btn ac-btn-goto-form">
          <div className="ac-inner-btn">
            <span>ליקום הבא</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link>
      </PopupEnd>
    );
  }
};

export default FoundOreo;
