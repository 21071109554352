import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FoundOreo from "./Popup/FoundOreo";
import { NotFoundData } from "./Popup/NotFoundData";
import NotFoundOreo from "./Popup/NotFoundOreo";
import YalahPopup from "./Popup/YalahPopup";
import { ServerTime } from "./Utility";

const VRScene = (props) => {
  const [serverStop, setServerStop] = useState();
  const isFound = () => {
    props.setIsNotFound(false);
    props.pause();
  };
  const IsFoundNot = () => {
    props.setNotOreo(false);
    // props.pause();
  };
  const number = Math.floor(Math.random() * NotFoundData.length);
  const [response, setresponse] = useState();
  const [serverStart, setServerStart] = useState();
  // const [IsSleep, setIsSleep] = useState(false);
  const location = useLocation();
  const [utmSource] = useState(
    new URLSearchParams(location?.search).get("utm_source")
  );
  const [utmMedium] = useState(
    new URLSearchParams(location?.search).get("utm_medium")
  );
  const [utm_val] = useState(location?.search);


  useEffect(() => {
    // window.addEventListener("touchmove", (event) => {
    //   var absolute, alpha, betam, gamma, stepRotation;
    //   function handleOrientation(event) {
    //     console.log("Event: ", event);
    //     var absolute = event.absolute;
    //     var alpha = event.alpha;
    //     var beta = event.beta;
    //     var gamma = event.gamma;
    //     // // Do stuff with the new orientation data
    //     // console.log(event.currentTarget.AFRAME.scenes[0].clock.elapsedTime); //TIME

    //     // var stepRotation =
    //     //   event.srcElement.AFRAME.scenes[0].behaviors.tick[0].yawObject.rotation
    //     //     ._y;
    //     var stepRotation =
    //       event?.srcElement?.AFRAME?.scenes[0]?.behaviors?.tock[0]?.data
    //         ?.direction;
    //     console.log(stepRotation);
    //     console.log(gamma);
    //   }
    //   // handleOrientation(event);
    //   window.addEventListener("deviceorientation", handleOrientation, true);
    //   // console.log($orientation);
    // });
    const getServerTime = ServerTime;
    setServerStart(getServerTime);
    // console.log(serverStart);
    // alert(serverStart);

    if (!props.Yalah) {
      props.run();
    }
    return () => {
      // props.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.Yalah]);

  useEffect(() => {
    // switch (props.statusGame) {
    //   case 1:
    //     setresponse(props.steps[0](isFound, IsFoundNot));
    //     break;
    //   case 2:
    //     setresponse(props.steps[1](isFound, IsFoundNot));
    //     break;
    //   case 3:
    //     setresponse(props.steps[2](isFound, IsFoundNot));
    //     break;
    //   case 4:
    //     setresponse(props.steps[3](isFound, IsFoundNot));
    //     break;
    //   case 5:
    //     setresponse(props.steps[3](isFound, IsFoundNot));
    //     break;
    //   default:
    //     setresponse("error");
    //     break;
    // }
    switch (props.statusGame) {
      case 1:
        setresponse(props.steps[0](isFound, IsFoundNot));

        break;
      case 2:
        setresponse(props.steps[1](isFound, IsFoundNot));
        break;
      case 3:
        setresponse(props.steps[2](isFound, IsFoundNot));
        break;
      case 4:
        setresponse(props.steps[3](isFound, IsFoundNot));
        break;
      default:
        setresponse("error");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.statusGame]);
  return (
    <div>
      <div id="MAINWRAPPER">
        <div id="WRAP">
          <div className="frame">{response}</div>
        </div>
      </div>

      {props.Yalah ? (
        <YalahPopup
          Yalah={props?.Yalah}
          VR360={props?.VR360}
          setYalah={props?.setYalah}
        />
      ) : null}
      {!props.isNotFound ? (
        <FoundOreo
          step={props.statusGame}
          desactivate={props.setIsNotFound}
          replay={props.resume}
          change={props.changeStatus}
        />
      ) : null}
      {!props.notOreo ? (
        <NotFoundOreo
          number={number}
          desactivate={props.setNotOreo}
          replay={props.resume}
        />
      ) : null}
      {/* {IsSleep?
      <IsSleepComp 
          desactivate={props.setNotOreo}
          replay={props.resume}
          setIsSleep={setIsSleep}
          handleReset={handleReset}
          handleStart={handleStart}  
          />
          :null
      } */}
      <div style={{ display: "none" }}>
        <Link
          to={{
            pathname: "/thanks",
            time: props.timeer,
            utmMedium: utmMedium,
            utmSource: utmSource,
            utm_val: utm_val,
            isDev: props.isDev,
            serverStart: serverStart,
          }}
          id="passtothanks"
        />
      </div>
    </div>
  );
};

export default VRScene;
