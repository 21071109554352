import React, { useState } from "react";
import Popup from "../Popup";
import { Link } from "react-router-dom";
import TagManager from "react-gtm-module";

const ThanksPopup = (props) => {
  const [closePopup, setClosePopup] = useState(false);
  const eventClick = () => {
    TagManager.dataLayer({ dataLayer: { event: "yalla" } });
  };
  return (
    <div
      id="ac-thanks-game"
      events={eventClick}
      className={closePopup ? "ac-close" : null}
    >
      <div class="ac-content">
        <div class="ac-close">
          <button class="ac-close-btn" onClick={() => setClosePopup(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.059"
              height="18.059"
              viewBox="0 0 18.059 18.059"
            >
              <g data-name="Group 475" transform="translate(-61.246 -225.649)">
                <g data-name="Group 395" transform="translate(64.645 226.231)">
                  <path
                    fill="#fff"
                    d="M0 0a1.991 1.991 0 00-2.815 0 1.989 1.989 0 000 2.815l14.077 14.078a1.991 1.991 0 102.815-2.816z"
                    data-name="Path 219"
                  ></path>
                </g>
                <g data-name="Group 396" transform="translate(61.829 240.309)">
                  <path
                    fill="#fff"
                    d="M0 0a1.991 1.991 0 002.815 2.815l14.078-14.077a1.991 1.991 0 000-2.815 1.991 1.991 0 00-2.816 0z"
                    data-name="Path 220"
                  ></path>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <span className="ac-title">טעים להכיר</span>
        <br />
        <span className="ac-text">
          יש לנו את הפרטים שלך
          <br /> וניצור איתך קשר במקרה
          <br />
          של זכייה.
          <br /> בינתיים: כדי להגדיל
          <br /> את הסיכוי לזכות אפשר
          <br />
          להמשיך לשחק ולהעלות
          <br />
          חשבוניות נוספות
        </span>
        <Link
          to="/"
          onClick={() => {
            // window.location.replace("https://oreoprod.activated.co.il/");

            TagManager.dataLayer({ dataLayer: { event: "lets_start" } });
          }}
          className="ac-btn ac-start-game-btn"
        >
          <div className="ac-inner-btn">
            <span>למשחק</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default ThanksPopup;
