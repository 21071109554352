import React, { useState } from "react";
import findOreoImg from "../assets/img/1.svg";

// import "../assets/css/GamePage.css";
import BannerGame from "../Components/BannerGame";
import { formatTime, formatTime_back } from "../Components/Chrono";
import { useSteps } from "../Components/Steps";
import VRScene from "../Components/VRScene";

export const GamePage = (props) => {
  const [isNotFound, setIsNotFound] = useState(true);
  const [isNotNissed, setIsNotMissed] = useState(true);
  const [isOpened, setisOpened] = useState(false);
  const { ref, handleaddStep } = useSteps();
  // 	var devtools = function() {};
  // 	devtools.toString = function() {
  // 	  if (!this.opened) {
  // 		setisOpened(true);
  // 	  }
  // 	  this.opened = true;
  // 	}
  // console.log(isOpened);
  // devtools.opened will become true if/when the console is opened
  return (
    <div className="ac-start-game">
      <VRScene
        steps={props.steps}
        notOreo={isNotNissed}
        setNotOreo={setIsNotMissed}
        Yalah={props.isNotYalla}
        img={findOreoImg}
        setYalah={props.setIsNotYalla}
        timeer={formatTime_back(props.timer)}
        run={props.handleStart}
        reset={props.handleReset}
        statusGame={ref.current}
        changeStatus={handleaddStep}
        resume={props.handleResume}
        pause={props.handlePause}
        isNotFound={isNotFound}
        setIsNotFound={setIsNotFound}
        VR360={props.VR360}
        isDev={props.isDev}
      />

      {!props.isNotYalla ? (
        <BannerGame step={ref.current} time={formatTime(props.timer)} />
      ) : null}
    </div>
  );
};

export default GamePage;
