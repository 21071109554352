// const crypto = require('crypto')


export const  getKey= (length=10) =>{
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}

export function isNumberKey(evt){
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    return true;
}
export function milis(){
    return Math.floor(Math.random()*(59-10+1)+10).toString();
}


const publicKeyString = '-----BEGIN RSA PUBLIC KEY-----\n'+
    'MIICCgKCAgEAzCFQZwKbt7zjmUn+RxVDAEkTerJWERw63abd+xUSnhaSYUgeS21s\n'+
    'XcPIDylxAUKE3LtcFXJFlX9sRtlCQ8Gr0YUKL9LxwpTNGjo4Rim+4wS2rgWYlGuN\n'+
    'Dbu9WC7Rv1vvkHH2i/+Y5JQjpue0wLHtGh59QDMhj7SWWA5iGhU9TkEdF+Ybmvzn\n'+
    'HISZAO3dqj0A1cR2mbFhHtuj7p8wWcZ//4ZPTwDVohAFm497k444aZh3JGY1ZvFb\n'+
    'Ju/2d5ZtotIXiXwWkLklScxuVYPZmiRKaVfEyt9Odq3tYCiP84F22GcrWjTW2gVq\n'+
    'gGRGu93SUyQRVLCwMV8u1hARbhjirneGbztx8gviJJ0/NDkCqVXxCN4lEWevybRh\n'+
    '0FIdr8Sj/kp7jBgFF3A/xx5xEiazB+wairpUjvHgIiIxX0ar7D5YTBFnoTE88UYZ\n'+
    'myQQGPwYw7d3eVswxGyc5ZKfZEt0aaN3xZscsccNqchYJ7dyR+q3uCI1AUdf41AL\n'+
    'RrgcsaeOxbRGyPznglrt5MpvFEfA+052JgAwrOctqJSMXJ/WxFFV6KOUsJrna87m\n'+
    'kfrNFvXY5bzJsGmfVsnpIJR//NUKfRXdVamzw+hP8sBBOInvurDk1V//gvPof25G\n'+
    'TgkpD3qCUgJ2nX7lkaR1j6gyxAsV5PRUaCSZwR3ABJXgRvOLsu8TDDkCAwEAAQ==\n'+
    '-----END RSA PUBLIC KEY-----';



 
// export function encrypt(toEncrypt) {
//     let key = {
//         key: publicKeyString,
//         padding: crypto.constants.RSA_PKCS1_PADDING
//       }
//     const buffer = Buffer.from(toEncrypt, 'utf8')
//     const encrypted = crypto.publicEncrypt(key, buffer)
//     return encrypted.toString('base64')
//   }