import React from "react";

// import _A1 from '../../assets/json/A1.json';
// import _A1 from '../../assets/Step/A1.js';
// import _A2 from '../../assets/Step/A2.js';
// import _A3 from '../../assets/Step/A3.js';
// import _A4 from '../../assets/Step/A4.js';
// import _A5 from '../../assets/Step/A5.js';
// import _A6 from '../../assets/Step/A6.js';

const Step_1 = (type) => {
  switch (type) {
    case "A1":
      return A1;
    case "A2":
      return A2;
    case "A3":
      return A3;
    case "A4":
      return A4;
    case "A5":
      return A5;
    case "A6":
      return A6;
    default:
      break;
  }
};

const A1 = (found, notFound) => {
  return (
    <a-scene
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      {/* <a-button
        style={{
          position: "absolute",
          top: "29vw",
          left: "1vw",
          width: "maxWidth",
          height: "4vw",
          background: "#000",
          color: "#fff",
          "z-index": "9",
        }}
        onClick={() => {
          found();
        }}
        material="opacity: 0.0; transparent: true"
      > */}
      {/* Change the pic
      </a-button> */}

      <a-sphere
        //a1
        onClick={() => {
          found();
        }}
        position="-6.5 -4 -5.2"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="15 -1 -12"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo 2 blue oreo
        onClick={() => notFound()}
        position="-13 -1 11"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 -0.5 6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 0 6"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a2
        onClick={() => {
          notFound();
        }}
        position="-12.5 1.7 2.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a3
        onClick={() => {
          notFound();
        }}
        position="1.5 1 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a5
        onClick={() => {
          notFound();
        }}
        position="7.5 2 -1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a6
        onClick={() => {
          notFound();
        }}
        position="1.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.A1.image.data}`} />
    </a-scene>
  );
};
const A2 = (found, notFound) => {
  return (
    <a-scene
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //a2
        onClick={() => {
          found();
        }}
        position="-12.5 1.7 2.45"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="15 -1 -12"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo 2 blue oreo
        onClick={() => notFound()}
        position="-13 -1 11"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 -0.5 6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 0 6"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a1
        onClick={() => {
          notFound();
        }}
        position="-6.5 -4 -5.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a3
        onClick={() => {
          notFound();
        }}
        position="1.5 1 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a4
        onClick={() => {
          notFound();
        }}
        position="-1 2.3 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a5
        onClick={() => {
          notFound();
        }}
        position="7.5 2 -1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a6
        onClick={() => {
          notFound();
        }}
        position="1.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.A2.image.data}`} />
    </a-scene>
  );
};
const A3 = (found, notFound) => {
  return (
    <a-scene
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      {/* <a-button
        style={{
          position: "absolute",
          top: "29vw",
          left: "1vw",
          width: "maxWidth",
          height: "4vw",
          background: "#000",
          color: "#fff",
          "z-index": "9",
        }}
        onClick={() => {
          found();
        }}
      >
        Change the pic
      </a-button> */}
      <a-sphere
        //a3
        onClick={() => {
          found();
        }}
        position="1.5 1 6.45"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="15 -1 -12"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo 2 blue oreo
        onClick={() => notFound()}
        position="-13 -1 11"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 -0.5 6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 0 6"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a1
        onClick={() => {
          notFound();
        }}
        position="-6.5 -4 -5.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a2
        onClick={() => {
          notFound();
        }}
        position="-12.5 1.7 2.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a4
        onClick={() => {
          notFound();
        }}
        position="-1 2.3 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a5
        onClick={() => {
          notFound();
        }}
        position="7.5 2 -1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a6
        onClick={() => {
          notFound();
        }}
        position="1.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.A3.image.data}`} />
    </a-scene>
  );
};
const A4 = (found, notFound) => {
  return (
    <a-scene
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      {/* <a-button
        style={{
          position: "absolute",
          top: "29vw",
          left: "1vw",
          width: "maxWidth",
          height: "4vw",
          background: "#000",
          color: "#fff",
          "z-index": "9",
        }}
        onClick={() => {
          found();
        }}
      >
        Change the pic
      </a-button> */}
      <a-sphere
        //a4
        onClick={() => {
          found();
        }}
        position="-1 2.3 6.45"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="15 -1 -12"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo 2 blue oreo
        onClick={() => notFound()}
        position="-13 -1 11"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 -0.5 6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 0 6"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a1
        onClick={() => {
          notFound();
        }}
        position="-6.5 -4 -5.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a3
        onClick={() => {
          notFound();
        }}
        position="1.5 1 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a5
        onClick={() => {
          notFound();
        }}
        position="7.5 2 -1.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a6
        onClick={() => {
          notFound();
        }}
        position="1.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.A4.image.data}`} />
    </a-scene>
  );
};
const A5 = (found, notFound) => {
  return (
    <a-scene
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      {/* <a-button
        style={{
          position: "absolute",
          top: "29vw",
          left: "1vw",
          width: "maxWidth",
          height: "4vw",
          background: "#000",
          color: "#fff",
          "z-index": "9",
        }}
        onClick={() => {
          found();
        }}
      >
        Change the pic
      </a-button> */}
      <a-sphere
        //a5
        onClick={() => {
          found();
        }}
        position="7.5 2 -1.2"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="15 -1 -12"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo 2 blue oreo
        onClick={() => notFound()}
        position="-13 -1 11"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 -0.5 6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 0 6"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a1
        onClick={() => {
          notFound();
        }}
        position="-6.5 -4 -5.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a3
        onClick={() => {
          notFound();
        }}
        position="1.5 1 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a4
        onClick={() => {
          notFound();
        }}
        position="-1 2.3 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a6
        onClick={() => {
          notFound();
        }}
        position="1.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.A5.image.data}`} />
    </a-scene>
  );
};
const A6 = (found, notFound) => {
  return (
    <a-scene
      rotation="45 90 180"
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      {/* <a-button
        style={{
          position: "absolute",
          top: "29vw",
          left: "1vw",
          width: "maxWidth",
          height: "4vw",
          background: "#000",
          color: "#fff",
          "z-index": "9",
        }}
        onClick={() => {
          found();
        }}
      >
        Change the pic
      </a-button> */}
      <a-sphere
        //a6
        onClick={() => {
          found();
        }}
        position="1.5 -3.5 5"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="15 -1 -12"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo 2 blue oreo
        onClick={() => notFound()}
        position="-13 -1 11"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 -0.5 6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //air conditioner
        onClick={() => notFound()}
        position="10.5 0 6"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a1
        onClick={() => {
          notFound();
        }}
        position="-6.5 -4 -5.2"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a3
        onClick={() => {
          notFound();
        }}
        position="1.5 1 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //a4
        onClick={() => {
          notFound();
        }}
        position="-1 2.3 6.45"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />

      <a-sky src={`data:image/jpeg;base64,${window.A6.image.data}`} />
    </a-scene>
  );
};

export default Step_1;
