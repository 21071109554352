import React from "react";
import Popup from "../Popup";
import TagManager from "react-gtm-module";

const YalahPopup = (props) => {
  const eventClick = () => {
    props.setYalah(false);
    TagManager.dataLayer({ dataLayer: { event: "yalla" } });
  };
  return (
    <Popup
      id="ac-start-game"
      events={eventClick}
      className={!props.Yalah ? "ac-close" : null}
    >
      <div className="ac-img-wrapper ac-img-wrapper-2">
        <img src={props.VR360} alt="find Oreo" className="ac-img-responive" />
      </div>
      <p>
        הזיזו את הנייד לכל הכיוונים
        <br />
        וכשתמצאו את עוגיית האוראו
        <br />
        לחצו עליה
      </p>

      <button
        className="ac-btn ac-btn-yalla"
        onClick={() => {
          props.setYalah(false);
          TagManager.dataLayer({ dataLayer: { event: "start_play" } });
        }}
      >
        <div className="ac-inner-btn">
          <span>זוזו למשחק</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.167"
            height="21.168"
            viewBox="0 0 21.167 21.168"
          >
            <g data-name="Group 32" transform="translate(0 10.584)">
              <path
                fill="#0057c8"
                d="M0 0l21.167 10.584v-21.168z"
                data-name="Path 17"
              ></path>
            </g>
          </svg>
        </div>
      </button>
    </Popup>
  );
};
export default YalahPopup;
