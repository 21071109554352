import React from "react";
import logo from "../assets/img/Group3.png";
import car from "../assets/img/MaskGroup2@2x.png";
import "../assets/css/OpeningPage.css";
import GamePage from "./GamePage";
import TagManager from "react-gtm-module";

const OpeningPage = (props) => {
  if (props.isStart) {
    return (
      <GamePage
        VR360={props.VR360}
        steps={props.steps}
        setIsNotYalla={props.setIsNotYalla}
        isNotYalla={props.isNotYalla}
        timer={props.timer}
        handleStart={props.handleStart}
        handlePause={props.handlePause}
        handleResume={props.handleResume}
        handleReset={props.handleReset}
        isDev={props.isDev}
      />
    );
  }
  return (
    <div
      className="ac-openning-page"
      // style={{
      //   backgroundImage: `url(${
      //     process.env.PUBLIC_URL + "/img/startgameBg.jpg"
      //   })`,
      //   backgroundPosition: "center",
      //   backgroundRepeat: "noRepeat",
      //   backgroundSize: "100%",
      // }}
    >
      <div className="ac-container">
        <div className="ac-img-wrapper">
          <img src={logo} alt="Logo" className="ac-img-responive" />
        </div>
        <p>
          <span class="ac-subtitle">
            מוצאים ת׳אוראו במולטיוורס
            <br />
            ויכולים לזכות במיני קופר!
          </span>
          <br />
          אי שם במפעלי אוראו בעולם,
          <br />
          התרחש אירוע קוסמי נדיר, <br />
          שבמהלכו 4 עוגיות אוראו <br />
          נשאבו ל- 4 יקומים מקבילים.
        </p>
        <p>
          <strong>
            מצאו ת׳אוראו הכי מהר <br />
            ותוכלו לזכות במיני קופר!
          </strong>
        </p>
        <div
          onClick={() => {
            props.setIsStart(true);
            TagManager.dataLayer({ dataLayer: { event: "go_play" } });
          }}
          className="ac-btn ac-start-game-btn"
        >
          <div className="ac-inner-btn">
            <span>למשחק</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </div>
        <div className="ac-img-wrapper ac-car">
          <img src={car} alt="car" className="ac-img-responive" />
        </div>
      </div>
    </div>
  );
};

export default OpeningPage;
