import React, { useState } from 'react';
import "../assets/css/Popp.css";
import closeImg from '../assets/img/close.svg';
export const PopupEnd = (props) => {
    const [popupOpen, setpopupOpen ] = useState(true);
    
    let popupCssClass = ' ac-open';
    if(popupOpen === false) {
        popupCssClass = ' ac-close';
    }
	return(
		<div className={`ac-wrapper-popup` + popupCssClass + ' ' + props.cssClass} id={props.id}>
            <div className="ac-inner">
                <div className="ac-content">
                 {props.children}
                </div>
            </div>
        </div>
	)
}

export default PopupEnd 