import React from "react";
// import _C1 from '../../assets/Step/C1';
// // import _C2 from '../../assets/Step/C2';
// // import _C3 from '../../assets/Step/C3';
// // import _C4 from '../../assets/Step/C4';
// // import _C5 from '../../assets/Step/C5';
// // import _C6 from '../../assets/Step/C6';
const Step_3 = (type) => {
  switch (type) {
    case "C1":
      return C1;
    case "C2":
      return C2;
    case "C3":
      return C3;
    case "C4":
      return C4;
    case "C5":
      return C5;
    case "C6":
      return C6;
    default:
      break;
  }
};
export default Step_3;
export const C1 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //C1
        onClick={() => {
          found();
        }}
        position="7 -2.95 3.48"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C6
        onClick={() => {
          notFound();
        }}
        position="-1.2 2.5 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C5
        onClick={() => {
          notFound();
        }}
        position="-3.5 2.8 -6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C4
        onClick={() => {
          notFound();
        }}
        position="2.2 -1 -6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C3
        onClick={() => {
          notFound();
        }}
        position="-5.2 -0.5 -3.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.C1.image.data}`} />
    </a-scene>
  );
};
export const C2 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //C2
        onClick={() => {
          found();
        }}
        position="-6.2 4.2 6.48"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C1
        onClick={() => {
          notFound();
        }}
        position="7 -2.95 3.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C6
        onClick={() => {
          notFound();
        }}
        position="-1.2 2.5 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C5
        onClick={() => {
          notFound();
        }}
        position="-3.5 2.8 -6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C4
        onClick={() => {
          notFound();
        }}
        position="2.2 -1 -6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C3
        onClick={() => {
          notFound();
        }}
        position="-5.2 -0.5 -3.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.C2.image.data}`} />
    </a-scene>
  );
};
export const C3 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //C3
        onClick={() => {
          found();
        }}
        position="-5.2 -0.5 -3.8"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C2
        onClick={() => {
          notFound();
        }}
        position="-6.2 4.2 6.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C1
        onClick={() => {
          notFound();
        }}
        position="7 -2.95 3.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-7.2 0.5 5.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />

      <a-sky src={`data:image/jpeg;base64,${window.C3.image.data}`} />
    </a-scene>
  );
};
export const C4 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //C4
        onClick={() => {
          found();
        }}
        position="2.2 -1 -6.8"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C3
        onClick={() => {
          notFound();
        }}
        position="-5.2 -0.5 -3.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C2
        onClick={() => {
          notFound();
        }}
        position="-6.2 4.2 6.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C1
        onClick={() => {
          notFound();
        }}
        position="7 -2.95 3.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-7.2 0.5 5.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />

      <a-sky src={`data:image/jpeg;base64,${window.C4.image.data}`} />
    </a-scene>
  );
};
export const C5 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //C5
        onClick={() => {
          found();
        }}
        position="-3.5 2.8 -6.8"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C4
        onClick={() => {
          notFound();
        }}
        position="2.2 -1 -6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C3
        onClick={() => {
          notFound();
        }}
        position="-5.2 -0.5 -3.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C2
        onClick={() => {
          notFound();
        }}
        position="-6.2 4.2 6.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C1
        onClick={() => {
          notFound();
        }}
        position="7 -2.95 3.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-7.2 0.5 5.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C6
        onClick={() => {
          notFound();
        }}
        position="-1.2 2.5 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />

      <a-sky src={`data:image/jpeg;base64,${window.C5.image.data}`} />
    </a-scene>
  );
};
export const C6 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //C6
        onClick={() => {
          found();
        }}
        position="-1.2 2.5 7"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C5
        onClick={() => {
          notFound();
        }}
        position="-3.5 2.8 -6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C4
        onClick={() => {
          notFound();
        }}
        position="2.2 -1 -6.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C3
        onClick={() => {
          notFound();
        }}
        position="-5.2 -0.5 -3.8"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C2
        onClick={() => {
          notFound();
        }}
        position="-6.2 4.2 6.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //C1
        onClick={() => {
          notFound();
        }}
        position="7 -2.95 3.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //not oreo
        onClick={() => {
          notFound();
        }}
        position="-7.2 0.5 5.48"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.C6.image.data}`} />
    </a-scene>
  );
};
