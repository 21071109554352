import React from "react";
// import _D1 from '../../assets/Step/D1';
// import _D2 from '../../assets/Step/D2';
// import _D3 from '../../assets/Step/D3';
// import _D4 from '../../assets/Step/D4';
// import _D5 from '../../assets/Step/D5';
// import _D6 from '../../assets/Step/D6';

const Step_4 = (type) => {
  switch (type) {
    case "D1":
      return D1;
    case "D2":
      return D2;
    case "D3":
      return D3;
    case "D4":
      return D4;
    case "D5":
      return D5;
    case "D6":
      return D6;
    default:
      break;
  }
};
export default Step_4;
const D1 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //d1
        onClick={() => {
          found();
        }}
        position="-7.5 4.5 -0.35"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="-3.5 1 3"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d2
        onClick={() => {
          notFound();
        }}
        position="1.9 -0.3 -6.35"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d6
        onClick={() => {
          notFound();
        }}
        position="8.5 6.4 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d5
        onClick={() => {
          notFound();
        }}
        position="-1.1 0.2 11"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d4
        onClick={() => {
          notFound();
        }}
        position="-6.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d3
        onClick={() => {
          notFound();
        }}
        position="2.9 2.7 -6.35"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.D1.image.data}`} />
    </a-scene>
  );
};
const D2 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //d2
        onClick={() => {
          found();
        }}
        position="1.9 -0.3 -6.35"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="-3.5 1 3"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d6
        onClick={() => {
          notFound();
        }}
        position="8.5 6.4 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d5
        onClick={() => {
          notFound();
        }}
        position="-1.1 0.2 11"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d4
        onClick={() => {
          notFound();
        }}
        position="-6.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d3
        onClick={() => {
          notFound();
        }}
        position="2.9 2.7 -6.35"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.D2.image.data}`} />
    </a-scene>
  );
};
const D3 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //d3
        onClick={() => {
          found();
        }}
        position="2.9 2.7 -6.35"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="-3.5 1 3"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d6
        onClick={() => {
          notFound();
        }}
        position="8.5 6.4 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d5
        onClick={() => {
          notFound();
        }}
        position="-1.1 0.2 11"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d4
        onClick={() => {
          notFound();
        }}
        position="-6.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.D3.image.data}`} />
    </a-scene>
  );
};
const D4 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //d4
        onClick={() => {
          found();
        }}
        position="-6.5 -3.5 5"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="-3.5 1 3"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d6
        onClick={() => {
          notFound();
        }}
        position="8.5 6.4 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d5
        onClick={() => {
          notFound();
        }}
        position="-1.1 0.2 11"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.D4.image.data}`} />
    </a-scene>
  );
};
const D5 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //d5
        onClick={() => {
          found();
        }}
        position="-1.1 0.2 11"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="-3.5 1 3"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d6
        onClick={() => {
          notFound();
        }}
        position="8.5 6.4 7"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d4
        onClick={() => {
          notFound();
        }}
        position="-6.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d3
        onClick={() => {
          notFound();
        }}
        position="2.9 2.7 -6.35"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d1
        onClick={() => {
          notFound();
        }}
        position="-7.5 4.5 -0.35"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.D5.image.data}`} />
    </a-scene>
  );
};
const D6 = (found, notFound) => {
  return (
    <a-scene
      cursor="rayOrigin: mouse"
      // device-orientation-permission-ui="enabled: false"
      vr-mode-ui="enabled: false"
      networked-scene="room: dev; debug: true; adapter: socketio;"
    >
      <a-sphere
        //d6
        onClick={() => {
          found();
        }}
        position="8.5 6.4 7"
        radius="0.3"
        color="red"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //logo blue oreo
        onClick={() => notFound()}
        position="-3.5 1 3"
        radius="1"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d4
        onClick={() => {
          notFound();
        }}
        position="-6.5 -3.5 5"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d3
        onClick={() => {
          notFound();
        }}
        position="2.9 2.7 -6.35"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sphere
        //d1
        onClick={() => {
          notFound();
        }}
        position="-7.5 4.5 -0.35"
        radius="0.3"
        color="#000"
        material="opacity: 0.0; transparent: true"
      />
      <a-sky src={`data:image/jpeg;base64,${window.D6.image.data}`} />
    </a-scene>
  );
};
