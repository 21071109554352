// import React from "react"
import useState from 'react-usestateref'


export const useSteps = () => {
  const [step, setStep,ref] =useState(1)
  

  const handleaddStep= () => {
    let step_help=step+1;
    setStep(step_help);
  } 
  return { ref,handleaddStep}
}

