import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PopupEnd from "../PopupEnd";
import { NotFoundData } from "./NotFoundData";

const NotFoundOreo = (props) => {
  const clickEvent = () => {
    props.desactivate(true);
    props.replay();
    props.desactivate(true);
    props.desactivate(true);
  };

  const [timeLeft, setTimeLeft] = useState(2);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const Desactivate = () => {
    return (
      <button className="ac-btn ac-btn-grey " disabled={true}>
        <div className="ac-inner-btn">
          <span>({timeLeft}) חזרה למשחק</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.167"
            height="21.168"
            viewBox="0 0 21.167 21.168"
          >
            <g data-name="Group 32" transform="translate(0 10.584)">
              <path
                fill="#0057c8"
                d="M0 0l21.167 10.584v-21.168z"
                data-name="Path 17"
              ></path>
            </g>
          </svg>
        </div>
      </button>
    );
  };

  return (
    <PopupEnd id="ac-missed-oreo">
      {/* <div className="ac-popup-title">
            <span className="ac-title-name ac-title-name-1">{NotFoundData[props.number].title}</span>
            <span className="ac-title-name ac-title-name-2">{NotFoundData[props.number].title}</span>
        </div> */}
      <p
        dangerouslySetInnerHTML={{ __html: NotFoundData[props.number].desc }}
      />
      {timeLeft === null ? (
        <Link to="" onClick={clickEvent} className="ac-btn ac-btn-start-again">
          <div className="ac-inner-btn">
            <span>חזרה למשחק</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21.167"
              height="21.168"
              viewBox="0 0 21.167 21.168"
            >
              <g data-name="Group 32" transform="translate(0 10.584)">
                <path
                  fill="#0057c8"
                  d="M0 0l21.167 10.584v-21.168z"
                  data-name="Path 17"
                ></path>
              </g>
            </svg>
          </div>
        </Link>
      ) : (
        <Desactivate />
      )}
    </PopupEnd>
  );
};
export default NotFoundOreo;
