import React, { useState } from "react";
import "../assets/css/Popp.css";
import closeImg from "../assets/img/close.svg";
export const Popup = (props) => {
  const [popupOpen, setpopupOpen] = useState(true);

  let popupCssClass = " ac-open";
  if (popupOpen === false) {
    popupCssClass = " ac-close";
  }
  return (
    <div
      className={`ac-wrapper-popup` + popupCssClass + " " + props.cssClass}
      id={props.id}
    >
      <div className="ac-inner">
        <div className="ac-close">
          <button
            className="ac-close-btn"
            onClick={(e) =>
              props?.events
                ? props?.events()
                : props?.closetab
                ? props?.closetab(undefined)
                : setpopupOpen(false)
            }
          >
            {/* <img src={closeImg} alt="find Oreo" className="ac-img-responive"/> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.059"
              height="18.059"
              viewBox="0 0 18.059 18.059"
            >
              <g data-name="Group 396" transform="translate(.583 14.66)">
                <path
                  fill="#fff"
                  d="M0 0a1.991 1.991 0 002.815 2.815l14.078-14.077a1.991 1.991 0 000-2.815 1.991 1.991 0 00-2.816 0z"
                  data-name="Path 220"
                ></path>
              </g>
            </svg>
          </button>
        </div>
        <div className="ac-content">{props.children}</div>
      </div>
    </div>
  );
};

export default Popup;
