import React, { useEffect, useLayoutEffect, useState } from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import Loader from "./Components/Loader";
import { Menu } from "./Components/Menu";
import UpdatePicture from "./Components/Popup/UpdatePicture";
import Step_1 from "./Components/Step/Step1";
import Step_2 from "./Components/Step/Step2";
import Step_3 from "./Components/Step/Step3";
import Step_4 from "./Components/Step/Step4";

import FormPage from "./Pages/FormPage";
import OpeningPage from "./Pages/OpeningPage";
import PricePage from "./Pages/PricePage";
import TakanonPage from "./Pages/TakanonPage";
import GoalsPage from "./Pages/GoalsPage";
import SharePage from "./Pages/SharePage";
import FaqPage from "./Pages/FaqPage";
import "./assets/css/StartGame.css";
import "reactjs-popup/dist/index.css";
import img360 from "./assets/img/360.svg";
import { useTimer } from "./Components/Chrono";
import { useForm } from "react-hook-form";
import { data } from "browserslist";

function App() {
  const [allLoading, setallLoading] = useState(false);
  const [isLoaded, setisLoaded] = useState(false);
  const [GameStep, setGameStep] = useState([]);
  const [isStart, setIsStart] = useState(false);
  const [isNotYalla, setIsNotYalla] = useState(true);
  const { timer, handleStart, handlePause, handleResume, handleReset } =
    useTimer(0);
  const [isOpenDev, setisOpenDev] = useState(isConsoleOpen());
  const [isPassword, setIsPassword] = useState(false);
  const [isVal, setIsVal] = useState();
  const [deviceType, setDeviceType] = useState("");

  const authenticate = () => {
    return new Promise((resolve) => setTimeout(resolve, 500)); // 2 seconds
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  useLayoutEffect(() => {
    function updateSize() {
      setisOpenDev(isConsoleOpen());
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  function isConsoleOpen() {
    var startTime = new Date();
    var endTime = new Date();
    // console.log(" endTime - startTime > 100", endTime - startTime > 100);
    return endTime - startTime > 100;
  }

  const ourFunctionName = async (id) => {
    const api_call = await fetch(`/json/${id}.json`);
    window[`${id}`] = await api_call.json();
  };
  useEffect(() => {
    // if (/iPhone/i.test(navigator.userAgent)) {
    //   setDeviceType("iphone");
    // } else {
    //   setDeviceType("Desktop");
    // }
    var arr = [];
    if (!allLoading) {
      var Step1 = ["A1", "A2", "A3", "A4", "A5", "A6"];
      var random_1 = Math.floor(Math.random() * Step1.length);

      arr.push(Step_1(Step1[random_1]));
      ourFunctionName(Step1[random_1]).then(() => {
        // arr.push(Step_1(Step1[3]));
        // ourFunctionName(Step1[3]).then(() => {
        authenticate().then(() => {
          const ele = document.getElementById("ac-progess-loading");
          const ele2 = document.getElementById("root");
          if (ele2 && ele) {
            setTimeout(() => {
              ele.classList.add("available");
              ele2.style.display = "block";
              ele.outerHTML = "";
              setisLoaded(true);
            }, 500);
          }
        });
      });

      setallLoading(true);

      var Step2 = ["B1", "B2", "B3", "B4", "B5", "B6"];
      var random_2 = Math.floor(Math.random() * Step2.length);
      arr.push(Step_2(Step2[random_2]));
      ourFunctionName(Step2[random_2]);
      // arr.push(Step_2(Step2[5]));
      // ourFunctionName(Step2[5]);

      ourFunctionName(Step2);

      var Step3 = ["C1", "C2", "C3", "C4", "C5", "C6"];
      var random_3 = Math.floor(Math.random() * Step3.length);
      ourFunctionName(Step3[random_3]);
      arr.push(Step_3(Step3[random_3]));
      // ourFunctionName(Step3[5]);
      // arr.push(Step_3(Step3[5]));

      var Step4 = ["D1", "D2", "D3", "D4", "D5", "D6"];
      var random_4 = Math.floor(Math.random() * Step4.length);
      ourFunctionName(Step4[random_4]);
      arr.push(Step_4(Step4[random_4]));
      // ourFunctionName(Step4[5]);
      // arr.push(Step_4(Step4[5]));

      setGameStep(arr);
    }
  }, [allLoading, isLoaded]);

  const onSubmit = () => {
    // console.log(isVal);
    if (isVal == "bny") {
      setIsPassword(true);
    }
  };

  // if(!isLoaded){
  //     return (<Loader/>)
  // }

  return (
    <div>
      {/* {isPassword ? ( */}
      <Router>
        <Menu loader={!isLoaded} isNotYalla={isNotYalla} />
        {/* <FormFinal/> */}
        {/* <UploadPictureToFirebase/>  */}

        <Routes>
          {/* <Route path="/start">
            <GamePage/>
          </Route> */}
          <Route path="/takanonPage"  element={<TakanonPage />}>
           
          </Route>
          <Route path="/sharePage" element={ <SharePage />}>
           
          </Route>
          <Route path="/goalsPage" element={ <GoalsPage
              isDev={isOpenDev}
              steps={GameStep}
              VR360={img360}
              isStart={isStart}
              setIsStart={setIsStart}
              isNotYalla={isNotYalla}
              setIsNotYalla={setIsNotYalla}
              timer={timer}
              handleStart={handleStart}
              handlePause={handlePause}
              handleResume={handleResume}
              handleReset={handleReset}
            />}></Route>
         
          <Route  exact path="/faqPage" element={<FaqPage
              isDev={isOpenDev}
              steps={GameStep}
              VR360={img360}
              isStart={isStart}
              setIsStart={setIsStart}
              isNotYalla={isNotYalla}
              setIsNotYalla={setIsNotYalla}
              timer={timer}
              handleStart={handleStart}
              handlePause={handlePause}
              handleResume={handleResume}
              handleReset={handleReset}
            />}></Route>
       
          <Route exact path="/thanks" element={<FormPage />} ></Route>
      


          <Route exact path="/" element={ <main id="mainOpening">
              <OpeningPage
                isDev={isOpenDev}
                steps={GameStep}
                VR360={img360}
                isStart={isStart}
                setIsStart={setIsStart}
                isNotYalla={isNotYalla}
                setIsNotYalla={setIsNotYalla}
                timer={timer}
                handleStart={handleStart}
                handlePause={handlePause}
                handleResume={handleResume}
                handleReset={handleReset}
              />
            </main>
          }></Route>
          </Routes>
      </Router>
    </div>
  );
}
export default App;
