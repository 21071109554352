import React, { useEffect, useState } from "react";
import App from "../App";
import DesktopPage from "../Pages/DesktopPage";

export function Lunch() {
  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? "landscape" : "portrait"
    ),
    onWindowResize = () => {
      clearTimeout(window.resizeLag);
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 200);
    };

  useEffect(
    () => (
      // eslint-disable-next-line no-sequences
      onWindowResize(),
      window.addEventListener("resize", onWindowResize),
      () => window.removeEventListener("resize", onWindowResize)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    []
  );

  if (orientation === "landscape" && window.innerWidth > 1000) {
    //  return (<p>You cannot use this site in portrait mode </p>)
    return <DesktopPage />;
  }
  //   else  if(window.innerWidth < 1000 && window.innerWidth > 600){
  //     alert('Rotate your device to portrait mode')
  //     return null;
  //  }

  return <App />;
}
