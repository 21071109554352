export const NotFoundData = [
  {
    title: "נופ!",
    desc: `זה לא אוראו,<br/>
אבל היקום<br/>
לא קורס...`,
  },
  {
    title: "נהההה....",
    desc: `זה לא אוראו,<br/>
אבל היקום<br/>
לא קורס...`,
  },
  {
    title: "אופסייייייי!",
    desc: `זה לא אוראו,<br/>
אבל היקום<br/>
לא קורס...`,
  },
  {
    title: "או או....",
    desc: `זה לא אוראו,<br/>
אבל היקום<br/>
לא קורס...`,
  },
];
